<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'7'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>闲赚</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('0')">
          <span>申请列表</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>闲赚伙伴</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>闲赚订单</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>申请列表</span>
        </div>
        <div class="date-search">
          <div class="right">
            <!-- <el-date-picker
              class="ml-40"
              v-model="starttime"
              type="date"
              placeholder="请选择起始日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-date-picker
              class="ml-40"
              v-model="endtime"
              type="date"
              placeholder="请选择结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-select class="ml-40" v-model="shopiing" placeholder="商品品种">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
            <el-select class="ml-40" v-model="state" placeholder="审核状态">
              <el-option
                v-for="item in stateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input class="ml-40" v-model="inpuValue" placeholder="真实名称"></el-input>
              <el-button icon="el-icon-search" type="primary" @click="selectBtn">搜索</el-button>
            <!-- <div class="ml-40 mt-20">
              
            </div> -->
          </div>
        </div>
        <div class="mt-20">
          <el-table
            :data="tableData.data"
            border
            style="width: 100%"
            :stripe="false"
          >
            <el-table-column
              prop="id"
              label="闲赚ID"
              width="120"
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="date"
              label="审核员"
              width="150"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              prop="date"
              label="用户头像"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <img
                  style="width: 80%"
                  :src="scope.row.user.avatarUrl"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="account"
              label="真实姓名"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="user.nickName"
              label="微信昵称"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="type"
              label="类型"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="审核状态"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="created_at"
              label="申请时间"
              width="230"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.$index, scope.row)"
                  >查看</el-button
                >
                <!-- <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="bottom-paging">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 20, 30, 100]"
            :page-size="num"
            layout="total, prev, pager, next, sizes"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      options: [],
      stateList: [
         {
          value: "0",
          label: "待审核",
        },
        {
          value: "2",
          label: "未通过",
        },
        {
          value: "1",
          label: "已通过",
        },
      ],
      isCollapse: false,
      starttime: "",
      endtime: "",
      shopiing: "",
      state: "",
      inpuValue: "",
      region: "",
      page: 1,
      num: 10,
      pickerOptions: {
        //控制时间范围
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 60 * 1000;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    selectBtn(){
      this.page = 1;
      this.getList();
    },
    getList() {
      let List = {
        page:this.page,
        num:this.num,
        name:this.inpuValue
      }
      if(this.state != ""){
        List.status = this.state;
      }
      this.$get("get/apply_dealer_backend", List).then((res) => {
        this.tableData = res.data.data;
        for (let i = 0; i < this.tableData.data.length; i++) {
          if (this.tableData.data[i].status == 0) {
            this.tableData.data[i].status = "待审核";
          } else if (this.tableData.data[i].status == 1) {
            this.tableData.data[i].status = "通过";
          } else {
            this.tableData.data[i].status = "驳回";
          }
        }
        console.log(res);
      });
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/idleearning") {
        this.$router.push("/idleearning");
      } else if (index == 1 && this.$route.path != "/partner") {
        this.$router.push("/partner");
      } else if (index == 2 && this.$route.path != "/idleearningorder") {
        this.$router.push("/idleearningorder");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.num = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    handleEdit(index, row) {
      this.$router.push({
        name: "idleearninginfo",
        query: {
          id: row.user_id,
        },
      });
      //   console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      margin-left: 40px;
      padding: 12px 55px;
    }
    .el-input {
      width: 200px;
    }
    .left,
    .right {
      display: inline-block;
    }
    .right {
      width: 1100px;
      vertical-align: top;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
